import cx from 'classnames'

import { type SanityRestaurantCoursesModule } from '@data/sanity/queries/types/modules'

import RestaurantCourseCard from '@components/restaurant-course-card'

type RestaurantCoursesProps = Pick<
  SanityRestaurantCoursesModule,
  'heading' | 'courses' | 'strings'
> & {
  className?: string
}

const RestaurantCourses = ({
  heading,
  courses,
  strings,
  className,
}: RestaurantCoursesProps) => {
  return (
    <section className="relative container py-12 sm:py-16 md:py-24 lg:py-32">
      <div
        className={cx(
          'flex flex-col gap-y-8 md:gap-y-10 text-pageText',
          className
        )}
      >
        {!!heading && <h2>{heading}</h2>}

        {!!courses && (
          <div className="flex flex-col md:grid md:grid-cols-2 gap-4 md:gap-6">
            {courses
              .filter(Boolean)
              .map(
                (
                  {
                    courseTitle,
                    pricePerPerson,
                    overrideItemsLabel,
                    itemsLabel,
                    items,
                  },
                  index
                ) => (
                  <RestaurantCourseCard
                    key={`${index}-${courseTitle}`}
                    title={courseTitle}
                    pricePerPerson={pricePerPerson}
                    items={items}
                    itemsLabel={overrideItemsLabel ? itemsLabel : undefined}
                    strings={strings}
                  />
                )
              )}
          </div>
        )}
      </div>
    </section>
  )
}

export default RestaurantCourses
